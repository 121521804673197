@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #ec4755;
  --color-secondary: #a12c34;
  --color-tertiaty: #99a0a3;
}

a {
  @apply text-red-700;
}

a:hover {
  @apply border-b-2 border-red-700;
}

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}
